// client/src/router/index.ts

import {
  createRouter,
  createWebHistory,
  RouteLocation,
  RouteRecordRaw,
} from "vue-router";
import SignUpView from "../views/SignUpView.vue";
import DashboardView from "../views/DashboardView.vue"; // Import the DashboardView
import SettingsView from "@/views/SettingsView.vue";
import AuthCallbackView from "../views/AuthCallbackView.vue";
import RootView from "../views/RootView.vue";
import CheckSignatureView from "@/views/CheckSignatureView.vue";
import AdminAccountsView from "@/views/admin/AccountsView.vue";
import AdminEmailsView from "@/views/admin/EmailsView.vue";
import AdminDashboardView from "@/views/admin/DashboardView.vue";
import AdminMapView from "@/views/admin/MapView.vue";
import AdminTransactionsView from "@/views/admin/TransactionsView.vue";
import AdminFinancesView from "@/views/admin/FinancesView.vue";
import AdminForwardsView from "@/views/admin/forwards/ForwardsView.vue";
import AdminForwardsEdit from "@/views/admin/forwards/ForwardsEdit.vue";
import AdminForwardReservationsView from "@/views/admin/forwards/ForwardReservationsView.vue";
import ForwardCertificateReservationsView from "@/views/admin/forwards/ForwardCertificateReservationsView.vue";
import EsoInfoView from "@/views/admin/integrations/lt-eso/EsoInfoView.vue";
import { authGuard } from "@auth0/auth0-vue";
import { inject, nextTick, unref } from "vue";
import { watchEffectOnceAsync } from "../composables/watchEffect";
import { useAuth0 } from "@auth0/auth0-vue";
import { authStore } from "@/store/authStore";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/callback", // Auth0 callback route
    name: "AuthCallback",
    meta: { title: "Sisse logimine" },
    component: AuthCallbackView,
    // Please note this route should not have authGuard
    // as it's meant to be accessed only after Auth0 redirect
    // which is when the user's authentication is being processed
    // and authGuard would cause a redirect loop
  },
  {
    path: "/",
    name: "Root",
    meta: { title: "Soldera" },
    component: RootView,
    beforeEnter: [authGuard, setAuth0Client],
  },
  {
    path: "/signup",
    name: "SignUp",
    meta: { title: "Soldera" },
    component: SignUpView,
    beforeEnter: [authGuard, setAuth0Client],
  },
  {
    // This page should be deleted, but it's here for legacy purposes in case anybody has unsigned documents in the dokobit
    // and will be redirected here
    // 16 August 2024, delete after 1st of january 2025
    path: "/document-check-signature",
    name: "CheckSignatureView",
    meta: { title: "Soldera" },
    component: CheckSignatureView,
    beforeEnter: [authGuard, setAuth0Client],
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: { title: "Soldera" },
    component: DashboardView,
    beforeEnter: [authGuard, setAuth0Client],
  },
  {
    path: "/settings",
    name: "Settings",
    meta: { title: "Soldera" },
    component: SettingsView,
    beforeEnter: [authGuard, setAuth0Client],
  },
  {
    path: "/admin/accounts",
    name: "AdminAccountsView",
    meta: { title: "Admin" },
    component: AdminAccountsView,
    beforeEnter: [authGuard, setAuth0Client],
  },
  {
    path: "/admin/emails",
    name: "AdminEmailsView",
    meta: { title: "Admin" },
    component: AdminEmailsView,
    beforeEnter: [authGuard, setAuth0Client],
  },
  {
    path: "/admin/dashboard",
    name: "AdminDashboardDashboard",
    meta: { title: "Admin" },
    component: AdminDashboardView,
    beforeEnter: [authGuard, setAuth0Client],
  },
  {
    path: "/admin/map",
    name: "AdminMapDashboard",
    meta: { title: "Admin" },
    component: AdminMapView,
    beforeEnter: [authGuard, setAuth0Client],
  },
  {
    path: "/admin/transactions",
    name: "AdminTransactionsView",
    meta: { title: "Admin" },
    component: AdminTransactionsView,
    beforeEnter: [authGuard, setAuth0Client],
  },
  {
    path: "/admin/finances",
    name: "AdminFinancesView",
    meta: { title: "Admin" },
    component: AdminFinancesView,
    beforeEnter: [authGuard, setAuth0Client],
  },
  {
    path: "/admin/forwards",
    name: "AdminForwardsView",
    meta: { title: "Admin" },
    component: AdminForwardsView,
    beforeEnter: [authGuard, setAuth0Client],
  },
  {
    path: "/admin/forwards/:forward_id",
    name: "AdminForwardsEdit",
    meta: { title: "Admin" },
    component: AdminForwardsEdit,
    beforeEnter: [authGuard, setAuth0Client],
  },
  {
    path: "/admin/forwards/:forward_id/reservations",
    name: "AdminForwardReservationsView",
    component: AdminForwardReservationsView,
    beforeEnter: [authGuard, setAuth0Client],
  },
  {
    path: "/admin/forwards/:forward_id/reservations/:reservation_id/certificates",
    name: "ForwardCertificateReservationsView",
    component: ForwardCertificateReservationsView,
    beforeEnter: [authGuard, setAuth0Client],
  },
  {
    path: "/admin/eso/info",
    name: "EspInfoView",
    component: EsoInfoView,
    beforeEnter: [authGuard, setAuth0Client],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: { name: "Root" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to) => {
  nextTick(() => {
    // Ensure the title is a string before setting it
    const title =
      typeof to.meta.title === "string" ? to.meta.title : "Default Title";
    document.title = title;
  });
});

async function setAuth0Client(to: RouteLocation) {
  if (to.name !== "AuthCallback") {
    const client = inject("auth0") as ReturnType<typeof useAuth0>;
    if (unref(client.isLoading)) {
      await watchEffectOnceAsync(() => !unref(client.isLoading));
    }

    if (!unref(client.isAuthenticated)) {
      await watchEffectOnceAsync(() => !unref(client.isAuthenticated));
    }

    try {
      await client.getAccessTokenSilently();
      authStore.setClient(client);
    } catch (error: any) {
      console.error("Error fetching user data:", error);
      throw error; // or handle the error as needed
    }
  }
  return true;
}

export default router;
