<!-- client/src/components/common/DocumentCardComponent.vue -->

<template>
  <div
    class="card document-card border-start-0 border-end-0 border-top-0 rounded-0"
  >
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <h5 class="card-title mb-0">{{ getDocumentTitle() }}</h5>
        <span
          :class="[
            'badge',
            document.status === 'completed'
              ? 'bg-success-subtle text-success-emphasis'
              : 'bg-warning-subtle text-warning-emphasis',
          ]"
        >
          {{
            document.status === "completed"
              ? $t("document.signed")
              : $t("document.awaiting_signature")
          }}
        </span>
      </div>
      <p class="card-text text-muted small">
        {{ $t("dashboard.documents.last_updated") }}: {{ document.updated_at }}
      </p>
      <div class="d-flex flex-wrap gap-2 mt-3">
        <button
          v-if="document.download_url"
          @click="downloadDocument(document.dokobit_token)"
          class="btn btn-outline-primary btn-sm"
          target="_blank"
        >
          <font-awesome-icon icon="download" class="me-1" />
          {{ $t("document.download") }}
        </button>
        <a
          v-if="
            document.document_signers.length > 0 &&
            document.status !== 'completed' &&
            document.document_signers[0].redirect_url
          "
          :href="document.document_signers[0].redirect_url"
          class="btn btn-primary btn-sm"
          target="_blank"
        >
          <font-awesome-icon icon="signature" class="me-1" />
          {{ $t("document.sign_document") }}
        </a>
        <button
          v-if="document.status !== 'completed'"
          class="btn btn-outline-danger btn-sm"
          @click="deleteDocument(document.dokobit_token)"
        >
          <font-awesome-icon icon="trash" class="me-1" />
          {{ $t("document.delete_document") }}
        </button>
      </div>
      <div class="mt-3">
        <button class="btn btn-link btn-sm p-0" @click="toggleContractDetails">
          {{
            showContractDetails
              ? $t("dashboard.documents.hide_details")
              : $t("dashboard.documents.show_details")
          }}
        </button>
        <div v-if="showContractDetails" class="mt-2">
          <ul class="list-unstyled">
            <li v-for="key in document.contract_keys" :key="key">
              <strong>{{ getDocumentInfo(key, "contract_title") }}:</strong>
              {{ getDocumentInfo(key, "contract_description") }}
            </li>
          </ul>
        </div>
      </div>

      <div v-if="generator">
        <button class="btn btn-link btn-sm p-0" @click="toggleAssociatedDevice">
          {{
            showContractDetails
              ? $t("dashboard.documents.hide_associated_device")
              : $t("dashboard.documents.show_associated_device")
          }}
        </button>
        <GeneratorCardComponent
          v-if="showAssociatedDevice"
          :key="generator.id"
          :generator="generator"
          :account_id="account_id"
          :title="
            $t('dashboard.companyData.device_contract', {
              deviceName: generator.name,
            })
          "
          :generatorDocuments="[]"
          :is_admin="is_admin"
        />
      </div>

      <!-- Admin Section -->
      <div
        v-if="is_admin"
        class="mt-3 alert alert-subtle-warning text-center admin-extra-info"
      >
        <button class="btn btn-link btn-sm p-0" @click="toggleAdminSection">
          {{
            showAdminSection
              ? $t("common.hide_admin_options")
              : $t("common.show_admin_options")
          }}
        </button>
        <div v-if="showAdminSection">
          <div class="d-flex flex-wrap gap-2 justify-content-center">
            <button
              class="btn btn-outline-warning btn-sm"
              @click="sendEleringEmail()"
            >
              <font-awesome-icon icon="envelope" class="me-1" />
              {{ $t("document.resend_email") }}
            </button>
            <button
              class="btn btn-outline-danger btn-sm"
              @click="adminDeleteDocument(document.dokobit_token)"
            >
              <font-awesome-icon icon="trash" class="me-1" />
              {{ $t("document.admin_delete_document") }}
            </button>
          </div>
          <p class="mb-0 mt-2">Document ID: {{ document.id }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, onMounted, PropType, ref } from "vue";
import type { Document, Generator } from "../../types";
import { goSolidApi } from "../../composables/useGoSolidApi";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useI18n } from "vue-i18n";
import { useDownloadDocument } from "@/composables/useDownloadDocument";
import { predefinedDocuments } from "@/types/PredefinedDocuments";
import GeneratorCardComponent from "@/components/common/GeneratorCardComponent.vue";

export default defineComponent({
  name: "DocumentCardComponent",
  props: {
    document: {
      type: Object as PropType<Document>,
      required: true,
    },
    account_id: {
      type: Number,
      required: true,
    },
    is_admin: {
      type: Boolean,
      required: true,
    },
    generator: {
      type: Object as PropType<Generator>,
      required: false,
    },
  },
  components: {
    FontAwesomeIcon,
    GeneratorCardComponent,
  },
  setup(props) {
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;
    const { t } = useI18n();
    const { downloadDocument } = useDownloadDocument();
    const showContractDetails = ref(false);
    const showAssociatedDevice = ref(false);

    const toggleContractDetails = () => {
      showContractDetails.value = !showContractDetails.value;
    };

    const toggleAssociatedDevice = () => {
      showAssociatedDevice.value = !showAssociatedDevice.value;
    };

    const showAdminSection = ref(false);
    const toggleAdminSection = () => {
      showAdminSection.value = !showAdminSection.value;
    };

    const getDocumentTitle = () => {
      if (props.document.contract_keys.length > 0) {
        const document_name = getDocumentInfo(
          props.document.contract_keys[0],
          "contract_title"
        );
        return t("dashboard.documents.document_title", {
          document_name,
          updated_at: props.document.updated_at,
        });
      }
      return t("dashboard.documents.other_document", {
        updated_at: props.document.updated_at,
      });
    };

    const getDocumentInfo = (
      key: string,
      infoType: "contract_title" | "contract_description"
    ) => {
      // Prefer metadata from the document itself if available
      if (props.document.file_metadata && props.document.file_metadata[key]) {
        if (infoType === "contract_title") {
          return props.document.file_metadata[key].title;
        } else if (infoType === "contract_description") {
          return props.document.file_metadata[key].description;
        }
      }

      // Fallback to predefined documents if metadata is not available
      const docInfo = predefinedDocuments[key];
      if (docInfo) {
        return t(docInfo[infoType]);
      }

      // Finally fallback to the key if translation is not found
      return key;
    };

    const deleteDocument = async (token: string) => {
      try {
        // Ask for confirmation before deleting the document
        const isConfirmed = window.confirm(t("document.confirm_delete"));

        if (!isConfirmed) {
          return;
        }
        const response = await goSolidApi.delete(
          `/api/document-delete/${token}`
        );
        if (response.status === 200) {
          location.reload();
        } else {
          throw new Error("Error deleting the document");
        }
      } catch (error: any) {
        globalErrorHandler(error.response.data);
      }
    };

    const adminDeleteDocument = async (token: string) => {
      try {
        // Ask for confirmation before deleting the document
        const isConfirmed = window.confirm(t("document.confirm_admin_delete"));

        if (!isConfirmed) {
          return;
        }
        const response = await goSolidApi.delete(
          `/api/admin/document/${token}`
        );
        if (response.status === 200) {
          location.reload();
        } else {
          throw new Error("Error deleting the document");
        }
      } catch (error: any) {
        globalErrorHandler(error.response.data);
      }
    };

    const sendEleringEmail = async () => {
      try {
        const response = await goSolidApi.get(
          `/api/admin/send_elering_email/${props.document.id}`
        );
        if (response.status === 200) {
          alert("email sent");
        } else {
          throw new Error("Error sending elering email");
        }
      } catch (error: any) {
        globalErrorHandler(error.response.data);
      }
    };

    return {
      showContractDetails,
      downloadDocument,
      deleteDocument,
      sendEleringEmail,
      adminDeleteDocument,
      getDocumentTitle,
      getDocumentInfo,
      toggleContractDetails,
      toggleAssociatedDevice,
      showAssociatedDevice,
      showAdminSection,
      toggleAdminSection,
    };
  },
});
</script>

<style scoped>
.document-card {
  transition: box-shadow 0.3s ease-in-out;
}

.document-card:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.document-card .btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.admin-extra-info {
  margin-top: 1rem;
  padding: 0.5rem;
}
</style>
