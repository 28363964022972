<!-- client/src/components/dashboard/AdminContractUploadComponent.vue -->

<template>
  <div class="admin-contract-upload-component text-start" v-if="accountData.id">
    <!-- Checkbox for if this file will be considered signed or not -->
    <div class="form-check mb-3">
      <label class="form-check-label fw-bold" for="isSigned">
        Envelope requires user signature
      </label>
      <input
        class="form-check-input"
        type="checkbox"
        id="isSigned"
        v-model="requiresSignature"
      />
    </div>

    <!-- Iterating over all files in envelope-->

    <div v-for="(file, index) in files" :key="index" class="card p-3 mb-2">
      <div class="mb-4">
        <label for="contractKey" class="form-label"> Contract key </label>
        <input
          type="text"
          class="form-control"
          id="contractKey"
          v-model="file.contract_key"
        />
      </div>

      <div class="mb-3">
        <label for="fileTitle" class="form-label"> File title </label>
        <input
          type="text"
          class="form-control"
          id="fileTitle"
          v-model="file.title"
        />
      </div>

      <div class="mb-3">
        <label for="fileDescription" class="form-label">
          File description
        </label>
        <textarea
          class="form-control"
          id="fileDescription"
          v-model="file.description"
        ></textarea>
      </div>

      <UploadChecklistItemComponent
        v-if="requiresSignature || index === 0"
        :uploadTitle="'Upload file'"
        :supportedFormats="'Reminder: .adoc files are supported only when uploading signed contracts.'"
        :uploadUrl="'/api/admin/company/custom_file_upload'"
        :accountId="accountData.id"
        :queryParams="fileQueryParams(index).value"
        :fileUrl="file.file_url"
        :uploadErrorMessage="'Upload failed. Please try again.'"
        :hasErrors="false"
        @uploadComplete="processUploadComplete(index, $event)"
      />
      <button class="btn btn-subtle-danger" @click="removeFile(index)">
        <font-awesome-icon icon="trash" />
        Remove file
      </button>
    </div>
    <div class="d-flex justify-content-between mt-3">
      <button class="btn btn-primary" @click="addFile">
        <font-awesome-icon icon="plus" />
        Add file
      </button>
      <button
        class="btn btn-success"
        @click="submitCustomContract"
        :disabled="isLoading"
      >
        <font-awesome-icon icon="check" />
        Submit custom contract
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  PropType,
  ref,
  computed,
  inject,
} from "vue";
import { Account, FileMetadataItem } from "@/types";
import UploadChecklistItemComponent from "@/components/common/UploadChecklistItemComponent.vue";
import { goSolidApi } from "@/composables/useGoSolidApi";

export default defineComponent({
  name: "AdminContractUploadComponent",
  components: { UploadChecklistItemComponent },
  props: {
    accountData: {
      type: Object as PropType<Account>,
      required: true,
    },
    companyIndex: {
      type: Number,
      required: true,
    },
  },
  emits: ["updateAccount"],
  setup(props, { emit }) {
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;
    const handleSuccess = inject("globalSuccessHandler") as (
      message: string,
      title: string
    ) => void;

    const requiresSignature = ref(true);
    const files = ref<FileMetadataItem[]>([]);
    const selectedCompany = computed(
      () => props.accountData.companies[props.companyIndex]
    );
    const isLoading = ref(false);

    const fileQueryParams = (index: number) =>
      computed(() => {
        return new Map<string, any>([
          ["company_id", selectedCompany.value.id],
          ["contract_key", files.value[index].contract_key],
        ]);
      });

    function removeFile(index: number) {
      files.value.splice(index, 1);
    }

    function addFile() {
      files.value.push({
        contract_key: "custom_",
        title: "",
        description: "",
        file_url: "",
      });
    }

    function processUploadComplete(index: number, response_data: any) {
      files.value[index].file_url = response_data.file_url;
    }

    async function submitCustomContract() {
      try {
        isLoading.value = true;
        const response = await goSolidApi.post(
          "/api/admin/company/custom_contract",
          {
            company_id: selectedCompany.value.id,
            requires_signature: requiresSignature.value,
            files: files.value,
          }
        );
        if (response.status === 200) {
          handleSuccess(
            "New custom contract submitted",
            "Can be found in contracts section"
          );
          files.value = [];
          emit("updateAccount", response.data.accountData);
        } else {
          throw new Error("Error submitting custom contract");
        }
      } catch (error) {
        globalErrorHandler(error);
      } finally {
        isLoading.value = false;
      }
    }

    onMounted(() => {
      addFile();
    });

    return {
      requiresSignature,
      files,
      isLoading,
      removeFile,
      addFile,
      fileQueryParams,
      processUploadComplete,
      submitCustomContract,
    };
  },
});
</script>

<style scoped>
.admin-contract-upload-component {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
</style>
