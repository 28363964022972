import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f244ac0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "dashboard-mobile-header",
  class: "navbar navbar-dark d-md-none"
}
const _hoisted_2 = { class: "container-fluid justify-content-between" }
const _hoisted_3 = { class: "mb-0 company-name" }
const _hoisted_4 = { id: "dashboard-sidebar" }
const _hoisted_5 = { class: "nav-title" }
const _hoisted_6 = { class: "company-list" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 0,
  class: "alert alert-subtle-warning text-center admin-extra-info"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["href"]
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "sidebar-call-to-action-title" }
const _hoisted_13 = ["href"]
const _hoisted_14 = {
  key: 0,
  class: "alert alert-subtle-warning text-center"
}
const _hoisted_15 = { class: "form-group" }
const _hoisted_16 = {
  id: "dashboard-wrapper",
  class: "pl-sm-3"
}
const _hoisted_17 = {
  id: "dashboard-content",
  class: "flex-grow-1 pt-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_company_data_component = _resolveComponent("company-data-component")!
  const _component_account_data_debug_component = _resolveComponent("account-data-debug-component")!
  const _component_admin_company_management_component = _resolveComponent("admin-company-management-component")!

  return (_openBlock(), _createElementBlock("div", {
    id: "dashboard",
    class: _normalizeClass({ 'd-flex': true, 'sidebar-open': _ctx.isSidebarOpen })
  }, [
    _createElementVNode("nav", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.selectedCompanyOrFirst
              ? _ctx.selectedCompanyOrFirst.name
              : _ctx.$t("dashboard.select_company")), 1),
        _createElementVNode("button", {
          class: "navbar-toggler",
          type: "button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSidebar && _ctx.toggleSidebar(...args)))
        }, [
          _createVNode(_component_font_awesome_icon, { icon: "caret-down" })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        type: "button",
        class: "btn-close btn-close-white",
        "aria-label": "Close",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleSidebar && _ctx.toggleSidebar(...args)))
      }),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("dashboard.companies")), 1),
      _createElementVNode("ul", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.accountData.companies, (company) => {
          return (_openBlock(), _createElementBlock("li", {
            key: company.id,
            class: _normalizeClass({
            'nav-item': true,
            'is-selected-nav': _ctx.selectedCompanyOrFirst?.id === company?.id,
          })
          }, [
            _createElementVNode("a", {
              class: "nav-link",
              onClick: ($event: any) => (_ctx.selectCompany(company.id!)),
              role: "button"
            }, [
              _createTextVNode(_toDisplayString(company.name) + " ", 1),
              (_ctx.accountData.admin_account_id)
                ? (_openBlock(), _createElementBlock("label", _hoisted_8, _toDisplayString(company.id), 1))
                : _createCommentVNode("", true)
            ], 8, _hoisted_7),
            (_ctx.getDocumentsReadyToSign(company).length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getDocumentsReadyToSign(company)[0]
                .document_signers, (signer) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: signer.id,
                      class: "sidebar-call-to-action"
                    }, [
                      _createElementVNode("a", {
                        href: signer.redirect_url,
                        class: "btn btn-subtle-primary btn-sm sidebar-call-to-action-button"
                      }, [
                        _createVNode(_component_font_awesome_icon, {
                          class: "px-1",
                          icon: "signature"
                        }),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t("dashboard.sign_document")), 1)
                      ], 8, _hoisted_10)
                    ]))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(company.generators.filter(
                (generator) => !generator.has_registered
              ), (generator) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: generator.id,
                      class: "sidebar-call-to-action"
                    }, [
                      _createElementVNode("div", _hoisted_12, " EIC: " + _toDisplayString(generator.device_eic), 1),
                      _createElementVNode("a", {
                        href: `/signup?step=2&generator_id=${generator.id}&company_id=${generator.company_id}`,
                        class: "btn btn-subtle-primary btn-sm sidebar-call-to-action-button"
                      }, [
                        _createVNode(_component_font_awesome_icon, {
                          class: "px-1",
                          icon: "play"
                        }),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t("dashboard.continue_registration")), 1)
                      ], 8, _hoisted_13)
                    ]))
                  }), 128))
                ]))
          ], 2))
        }), 128)),
        _createElementVNode("button", {
          class: "btn btn-link",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.goToRegisterNewDevice && _ctx.goToRegisterNewDevice(...args)))
        }, [
          _createVNode(_component_font_awesome_icon, { icon: "plus" }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("dashboard.add_company_or_device")), 1)
        ])
      ]),
      (_ctx.accountData.admin_account_id)
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t("dashboard.add_company_access")), 1),
              _withDirectives(_createElementVNode("input", {
                type: "number",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newCompanyId) = $event)),
                class: "form-control",
                placeholder: "$t(\"dashboard.company_id\")"
              }, null, 512), [
                [_vModelText, _ctx.newCompanyId]
              ])
            ]),
            _createElementVNode("button", {
              class: "btn btn-primary",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.confirmAddCompany && _ctx.confirmAddCompany(...args)))
            }, _toDisplayString(_ctx.$t("dashboard.add_company")), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("div", _hoisted_17, [
        (_ctx.accountData && _ctx.accountData.id && _ctx.selectedCompanyOrFirst)
          ? (_openBlock(), _createBlock(_component_company_data_component, {
              company: _ctx.selectedCompanyOrFirst,
              "onUpdate:company": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedCompanyOrFirst) = $event)),
              account_id: _ctx.accountData.id,
              is_admin: !!_ctx.accountData.admin_account_id,
              key: _ctx.selectedCompanyOrFirst.id
            }, null, 8, ["company", "account_id", "is_admin"]))
          : _createCommentVNode("", true),
        _createVNode(_component_account_data_debug_component, {
          accountData: _ctx.accountData,
          class: "mb-4"
        }, null, 8, ["accountData"]),
        (_ctx.accountData.admin_account_id)
          ? (_openBlock(), _createBlock(_component_admin_company_management_component, {
              accountData: _ctx.accountData,
              companyIndex: _ctx.companyIndexOrFirst,
              onUpdateAccount: _ctx.updateAccount,
              onUpdateCompany: _ctx.updateCompany,
              key: _ctx.companyIndexOrFirst
            }, null, 8, ["accountData", "companyIndex", "onUpdateAccount", "onUpdateCompany"]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}