<!-- client/src/components/signup/lt/ContractReviewConfigurationComponent.vue -->

<template>
  <ContractReviewDisplayComponent
    :accountData="accountData"
    :contractsToShow="contracts"
    :setLoading="setLoading"
    :signerFields="signerFields"
    :companyFields="companyFields"
    :generatorFields="generatorFields"
    :contractState="contractState"
    :hasGenerator="hasGenerator"
    @createContainerForStartSigningReady="
      $emit('createContainerForStartSigningReady', $event)
    "
  />
</template>

<script lang="ts">
import { useGeneratorFinder } from "@/composables/useGeneratorFinder";
import { useCompanyFinder } from "@/composables/useCompanyFinder";
import { Account, Document } from "@/types";
import { useRoute } from "vue-router";
import { defineComponent, reactive, computed, PropType, onMounted } from "vue";
import ContractReviewDisplayComponent from "@/components/signup/ContractReviewDisplayComponent.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "ContractReviewConfigurationComponent",
  props: {
    accountData: {
      type: Object as () => Account,
      required: true,
    },
    setLoading: {
      type: Function as PropType<(value: boolean) => void>,
      required: true,
    },
  },
  components: {
    ContractReviewDisplayComponent,
  },
  emits: ["createContainerForStartSigningReady"],
  setup(props) {
    const { t } = useI18n();
    const isAdmin = !!props.accountData.admin_account_id;
    const { companyIndex } = useCompanyFinder(props.accountData);
    const selectedCompany = computed(
      () => props.accountData.companies[companyIndex.value]
    );
    const { nullableSelectedGenerator } = useGeneratorFinder(props.accountData);
    const getGeneratorFileStatus = (fileKey: string) => {
      return computed(() => {
        if (
          nullableSelectedGenerator.value &&
          nullableSelectedGenerator.value.generator &&
          nullableSelectedGenerator.value.generator.files
        ) {
          return fileKey in nullableSelectedGenerator.value.generator.files
            ? t("common.uploaded")
            : t("common.not_uploaded");
        } else {
          return t("common.not_uploaded");
        }
      });
    };

    const route = useRoute();
    const addAuthorizationAgreement = computed(() => {
      return route.query.addAuthorizationAgreement === "true";
    });
    const contractState = computed(() => {
      if (addAuthorizationAgreement.value) {
        return 2;
      } else if (
        // Second device registration flow
        hasSignedOptionalContract("lt_litgrid_terms_service") ||
        hasSignedOptionalContract("lt_authotization_application")
      ) {
        return 3;
      }
      return 1;
    });

    const hasGenerator = computed(() => {
      return (
        !addAuthorizationAgreement.value &&
        nullableSelectedGenerator.value.generator !== null
      );
    });

    const signerFields = reactive([
      { label: t("account.name"), value: props.accountData.name },
      { label: t("account.email"), value: props.accountData.email },
      {
        label: t("account.phone_number"),
        value: props.accountData.phone_number,
      },
    ]);

    const companyFields = computed(() => {
      const baseFields = [
        { label: t("company.name"), value: selectedCompany.value.name },
        {
          label: t("company.registry_code"),
          value: selectedCompany.value.registry_code,
        },
      ];

      if (addAuthorizationAgreement.value) {
        return [
          ...baseFields,
          {
            label: t("signup.multiple_upload.lt_power_of_attorney"),
            value: getGeneratorFileStatus("lt_power_of_attorney"),
          },
        ];
      } else {
        return [
          ...baseFields,
          { label: t("company.vat"), value: selectedCompany.value.vat },
          {
            label: t("company.bank_account"),
            value: selectedCompany.value.bank_account,
          },
          {
            label: t("company.bank_name"),
            value: selectedCompany.value.bank_name,
          },
          {
            label: t("company.bank_swift"),
            value: selectedCompany.value.bank_swift,
          },
        ];
      }
    });

    const generatorFields = reactive([
      {
        label: t("generator.license_number"),
        value: nullableSelectedGenerator.value?.generator?.license_number,
      },
      {
        label: t("signup.multiple_upload.lt_inspection_report"),
        value: getGeneratorFileStatus("lt_inspection_report"),
      },
      {
        label: t("signup.multiple_upload.lt_property_limit_scheme"),
        value: getGeneratorFileStatus("lt_property_limit_scheme"),
      },
      {
        label: t("signup.multiple_upload.lt_power_of_attorney"),
        value: getGeneratorFileStatus("lt_power_of_attorney"),
      },
    ]);

    const hasSignedOptionalContract = (selectedContract: string) => {
      return selectedCompany.value?.documents.some(
        (document: Document) =>
          document.status === "completed" && // The contract needs to be signed
          document.contract_keys.includes(selectedContract) // and if it has our contract key, we want to return true
      );
    };

    const shouldSignOptionalContractIfOptional = (
      optionalContractKey: string | null
    ): boolean => {
      return (
        !optionalContractKey || !hasSignedOptionalContract(optionalContractKey)
      );
    };

    const includeDocument = (
      states: number[],
      optionalContractKey: string | null = null
    ) =>
      computed(() => {
        return (
          states.includes(contractState.value) &&
          shouldSignOptionalContractIfOptional(optionalContractKey)
        );
      });

    const litgridTOSContainer = [
      {
        id: 1,
        key: "lt_litgrid_terms_service",
        contract_title: t(
          "signup.contract_review_component.lt.lt_litgrid_terms_service_title"
        ),
        contract_description: t(
          "signup.contract_review_component.lt.lt_litgrid_terms_service_description"
        ),
        include: includeDocument([1], "lt_litgrid_terms_service").value,
        can_include: true,
      },
    ];
    const mainContainer = [
      {
        id: 1,
        key: "lt_device_registration",
        contract_title: t(
          "signup.contract_review_component.lt.lt_device_registration_title"
        ),
        contract_description: t(
          "signup.contract_review_component.lt.lt_device_registration_description"
        ),
        include: includeDocument([3]).value,
        can_include: true,
      },
      {
        id: 2,
        key: "lt_request_for_go_contract",
        contract_title: t(
          "signup.contract_review_component.lt.lt_request_for_go_contract_title"
        ),
        contract_description: t(
          "signup.contract_review_component.lt.lt_request_for_go_contract_description"
        ),
        include: includeDocument([1], "lt_request_for_go_contract").value,
        can_include: true,
      },
      {
        id: 3,
        key: "lt_inspection_report",
        contract_title: t("signup.multiple_upload.lt_inspection_report"),
        contract_description: t(
          "signup.contract_review_component.self_uploaded_file_text"
        ),
        include: includeDocument([1, 3]).value,
        can_include: true,
      },
      {
        id: 4,
        key: "lt_property_limit_scheme",
        contract_title: t("signup.multiple_upload.lt_property_limit_scheme"),
        contract_description: t(
          "signup.contract_review_component.self_uploaded_file_text"
        ),
        include: includeDocument([1, 3]).value,
        can_include: true,
      },
      {
        id: 5,
        key: "lt_authotization_application",
        contract_title: t(
          "signup.contract_review_component.lt.lt_authotization_application_title"
        ),
        contract_description: t(
          "signup.contract_review_component.lt.lt_authotization_application_text"
        ),
        include: includeDocument([1, 2], "lt_authotization_application").value,
        can_include: true,
      },
      {
        id: 6,
        key: "lt_soldera_membership_agreement",
        contract_title: t(
          "signup.contract_review_component.lt.lt_soldera_membership_agreement_title"
        ),
        contract_description: t(
          "signup.contract_review_component.lt.lt_soldera_membership_agreement_text"
        ),
        include: includeDocument([1, 2, 3], "lt_soldera_membership_agreement")
          .value,
        can_include: true,
      },
      {
        id: 7,
        key: "lt_power_of_attorney",
        contract_title: t("signup.multiple_upload.lt_power_of_attorney"),
        contract_description: t(
          "signup.contract_review_component.self_uploaded_file_text"
        ),
        include: includeDocument([1, 2, 3]).value,
        can_include: true,
      },
      {
        id: 8,
        key: "lt_litgrid_terms_service",
        contract_title: t(
          "signup.contract_review_component.lt.lt_litgrid_terms_service_title"
        ),
        contract_description: t(
          "signup.contract_review_component.lt.lt_litgrid_terms_service_description"
        ),
        include: includeDocument([10], "lt_litgrid_terms_service").value, // Include document 10, because it should only be able to be added by admin
        can_include: true,
      },
    ];

    // https://www.notion.so/solidworld/Lithuania-b85e093eb9544d8c86e122853d223ef5?pm=c
    const contracts = computed(() => {
      if (litgridTOSContainer[0].include) {
        return [mainContainer, litgridTOSContainer];
      } else {
        return [mainContainer];
      }
    });

    onMounted(() => {
      console.log("contract state", contractState.value);
      console.log(mainContainer);
    });

    return {
      contracts,
      signerFields,
      companyFields,
      generatorFields,
      contractState,
      hasGenerator,
      isAdmin,
    };
  },
});
</script>

<style scoped lang="scss"></style>
