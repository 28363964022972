<!-- client/src/components/dashboard/SetForwardSalesComponent.vue -->

<template>
  <div class="forward-sales">
    <h3 class="fs-6 fw-bold mb-3">Manage Forward Sales</h3>
    <div class="card text-dark bg-light mb-3" v-if="flowState === 'intro'">
      <div class="card-body">
        <h4 class="fs-7 fw-bold mb-3">About Soldera's Forward Sales Program</h4>
        <p class="mb-3">
          The Soldera Forward Sales program allows our VIP customers to
          automatically sell a percentage of their forward production projection
          over a set period of time. This program is designed to help you secure
          predictable revenue and reduce price fluctuation risks without having
          to worry about the day-to-day management of your forward sales.
        </p>
        <div class="row">
          <div class="col-md-6">
            <h5 class="fw-bold mb-2">Benefits</h5>
            <ul>
              <li class="mb-1">
                <b class="fw-bold">Customization:</b> Tell us how much of your
                production you want to sell and how far into the future. We take
                care of the rest.
              </li>
              <li class="mb-1">
                <b class="fw-bold">Stability:</b> Soldera reduces price
                fluctuation risks by selling your forward volume over time.
              </li>
              <li class="mb-1">
                <b class="fw-bold">Simplicity:</b> Relax while Soldera takes
                care of sales and delivery. Same invoicing process as usual.
              </li>
            </ul>
          </div>
          <div class="col-md-6">
            <h5 class="fw-bold mb-2">Requirements</h5>
            <ul>
              <li class="mb-1">
                <b class="fw-bold">Contract:</b> This service requires an
                signing additional contract that describes the terms of the
                forward sales.
              </li>
              <li class="mb-1">
                <b class="fw-bold">Delivery or replacement:</b> Production must
                be replaced if not delivered. Customize your strategy carefully.
              </li>
              <li class="mb-1">
                <b class="fw-bold">Minimum period:</b> Can't cancel contract
                before all forward volumes are settled.
              </li>
            </ul>
          </div>
          <div class="mt-3">
            <button
              class="btn btn-primary"
              @click="flowState = 'customization'"
            >
              Customize Your Strategy
            </button>
            <button class="btn btn-link ms-2">Contact Support</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row g-3" v-if="flowState === 'customization'">
      <form @submit.prevent="setForwardSales" class="col-md-6">
        <div class="text-dark bg-light border-translucent rounded-3 p-3">
          <h5 class="fs-7 fw-bold mb-3">
            Customize your Automated Forward Sales Strategy
          </h5>
          <label for="percentageOptions" class="form-label fs-9 px-1 mb-1">
            Select percentage of forward projection to sell
          </label>
          <div
            class="btn-group mb-3 btn-group-sm border border-translucent rounded-4 overflow-hidden d-flex"
            role="group"
            aria-label="percentageOptions"
          >
            <button
              v-for="percentage in [0, 20, 35, 50]"
              :key="percentage"
              type="button"
              class="btn px-0 w-25"
              :class="
                percentageForSale === percentage
                  ? 'btn-primary'
                  : 'btn-secondary border border-black'
              "
              @click="percentageForSale = percentage"
            >
              {{ percentage }}%
            </button>
          </div>

          <label for="maxMonthsOptions" class="form-label fs-9 px-1 mb-1">
            Maximum months into the future to sell
          </label>
          <div
            class="btn-group mb-3 btn-group-sm border border-translucent rounded-4 overflow-hidden d-flex"
            role="group"
            aria-label="maxMonthsOptions"
          >
            <button
              v-for="months in [6, 9, 12]"
              :key="months"
              type="button"
              class="btn px-0 w-33"
              :class="
                maxMonthsForward === months
                  ? 'btn-primary'
                  : 'btn-secondary border border-black'
              "
              @click="maxMonthsForward = months"
            >
              {{ months }}
            </button>
          </div>

          <label for="minimumPrice" class="form-label fs-9 px-1 mb-1">
            Minimum price (optional)
          </label>
          <div class="input-group mb-3">
            <input
              type="number"
              class="form-control"
              id="minimumPrice"
              v-model.number="localMinForwardPrice"
              placeholder="{{ $t('setPriceTerms.enterPrice') }}"
              min="0"
              step="0.01"
            />
            <span class="input-group-text"> € / MWh </span>
          </div>

          <div class="form-check mb-3">
            <input
              class="form-check-input"
              id="onlySellAboveSpot"
              type="checkbox"
              v-model="onlySellAboveSpot"
            />
            <label class="form-label fs-9 mx-1" for="onlySellAboveSpot">
              TODO BAD WORDING Only sell if forward price is higher than current
              spot price
            </label>
          </div>

          <button type="submit" class="btn btn-primary w-100">
            Review and Confirm
          </button>
        </div>
      </form>
      <div class="col-md-6">
        <StackedBarChart
          :chartData="saleStatusChartData"
          title="Forward sales by production month"
          subtitle="2025 production year estimate"
          yAxisTitle="Estimated Production (MWh)"
          :height="190"
        />
        <StackedBarChart
          :chartData="projectionChartData"
          title="Monthly production estimate by source"
          subtitle="2025 production year estimate"
          yAxisTitle="Estimated Production (GWh)"
          :height="190"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, toRefs, inject, onMounted, computed } from "vue";
import { PowerProjection } from "@/types";
import { goSolidApi } from "@/composables/useGoSolidApi";
import { CHART_COLORS } from "@/constants";
import StackedBarChart from "@/components/charts/StackedBarChart.vue";

export default defineComponent({
  name: "setForwardSalesComponent",
  props: {
    company_id: {
      type: Number,
      required: true,
    },
    minForwardPrice: {
      type: Number,
      default: 0,
    },
  },
  emits: ["update-forward-sales"],
  components: {
    StackedBarChart,
  },
  setup(props, { emit }) {
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;
    const { minForwardPrice } = toRefs(props);
    const localMinForwardPrice = ref(minForwardPrice.value);
    const percentageForSale = ref<number>(0);
    const onlySellAboveSpot = ref(true);
    const solarProjection = ref<PowerProjection | null>(null);
    const windProjection = ref<PowerProjection | null>(null);
    const maxMonthsForward = ref<number>(12);
    const flowState = ref("intro"); // Options are intro, customize

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const setForwardSales = () => {
      emit("update-forward-sales", {
        minForwardPrice: localMinForwardPrice.value,
        percentageForSale: percentageForSale.value,
        onlySellAboveSpot: onlySellAboveSpot.value,
      });
    };

    const getProjection = async () => {
      try {
        const response = await goSolidApi.get(
          `/api/company/${props.company_id}/projection`
        );
        if (response.status === 200) {
          solarProjection.value = response.data.projection.solar_projection;
          windProjection.value = response.data.projection.wind_projection;
        } else {
          throw new Error("Error fetching projections");
        }
      } catch (error) {
        globalErrorHandler(error);
      }
    };

    const projectionChartData = computed(() => ({
      labels: monthNames,
      datasets: [
        {
          label: "Wind",
          data: windProjection.value
            ? monthNames.map(
                (_, index) => windProjection.value![index + 1] || 0
              )
            : [],
          backgroundColor: CHART_COLORS.wind.background,
          borderColor: CHART_COLORS.wind.border,
          borderWidth: 1,
        },
        {
          label: "Solar",
          data: solarProjection.value
            ? monthNames.map(
                (_, index) => solarProjection.value![index + 1] || 0
              )
            : [],
          backgroundColor: CHART_COLORS.solar.background,
          borderColor: CHART_COLORS.solar.border,
          borderWidth: 1,
        },
      ],
    }));

    const saleStatusChartData = computed(() => {
      const totalProduction = monthNames.map((_, index) => {
        const solar = solarProjection.value
          ? solarProjection.value[index + 1] || 0
          : 0;
        const wind = windProjection.value
          ? windProjection.value[index + 1] || 0
          : 0;
        return solar + wind;
      });

      const forSaleData = totalProduction.map(
        (total) => total * (percentageForSale.value / 100)
      );
      const notForSaleData = totalProduction.map(
        (total) => total * (1 - percentageForSale.value / 100)
      );

      return {
        labels: monthNames,
        datasets: [
          {
            label: "Reserved",
            data: new Array(12).fill(0),
            backgroundColor: CHART_COLORS.sold.background,
            borderColor: CHART_COLORS.sold.border,
            borderWidth: 1,
          },
          {
            label: "For Sale",
            data: forSaleData,
            backgroundColor: CHART_COLORS.forSale.background,
            borderColor: CHART_COLORS.forSale.border,
            borderWidth: 1,
          },
          {
            label: "Spot Sales",
            data: notForSaleData,
            backgroundColor: CHART_COLORS.notForSale.background,
            borderColor: CHART_COLORS.notForSale.border,
            borderWidth: 1,
          },
        ],
      };
    });

    onMounted(() => {
      getProjection();
    });

    return {
      setForwardSales,
      percentageForSale,
      maxMonthsForward,
      onlySellAboveSpot,
      localMinForwardPrice,
      projectionChartData,
      saleStatusChartData,
      flowState,
    };
  },
});
</script>

<style scoped>
form {
  .form-label {
    text-transform: none;
  }
}
</style>
