export const SUPPORTED_COUNTRIES = ["EE", "LV", "LT"];

export const SUPPORTED_COUNTRIES_WITH_LABELS = [
  { value: "LV", label: "Latvia" },
  { value: "EE", label: "Estonia" },
  { value: "LT", label: "Lithuania" },
];

export const CHART_COLORS = {
  wind: {
    background: "rgba(54, 162, 235, 0.8)",
    border: "rgba(54, 162, 235, 1)",
  },
  solar: {
    background: "rgba(255, 159, 64, 0.8)",
    border: "rgba(255, 159, 64, 1)",
  },
  sold: {
    background: "rgba(75, 192, 192, 0.8)",
    border: "rgba(75, 192, 192, 1)",
  },
  forSale: {
    background: "rgba(255, 206, 86, 0.8)",
    border: "rgba(255, 206, 86, 1)",
  },
  notForSale: {
    background: "rgba(153, 102, 255, 0.8)",
    border: "rgba(153, 102, 255, 1)",
  },
};
